<script>
import VehicleDamageForm from './components/VehicleDamageForm';

export default {
  name: 'DamageReportView',
  components: {
    VehicleDamageForm,
  },
  props: {
    vehicleUuid: {
      type: String,
      required: true,
    },
    damageUuid: {
      type: String,
      default: null,
    },
  },
  computed: {
    isEditing() {
      return !!this.damageUuid;
    },
  },
};
</script>
<template>
  <div
    class="DamageDetailView d-flex flex-column flex-fill"
    data-test-id="damage_detail-view"
  >
    <h1 class="mb-2">
      {{ isEditing ? 'Edit damage' : 'Report new damage' }}
    </h1>
    <VehicleDamageForm v-bind="$props" />
  </div>
</template>
