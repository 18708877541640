<script>
import {
  GalleryComponent,
  GenericModalComponent,
} from '@/components';

export default {
  name: 'AttachmentPreviewModal',
  components: {
    GalleryComponent,
    GenericModalComponent,
  },
  props: {
    startIndex: {
      type: Number,
      default: 0,
    },
    attachments: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    title="Attachment preview"
    class="AttachmentPreviewModal v1-MuiModal--adaptative"
    data-test-id="documents-modal"
    v-on="$listeners"
  >
    <template #body>
      <div class="col">
        <GalleryComponent
          :images="attachments"
          :start-index="startIndex"
          data-test-id="gallery-component"
          class="h-100"
        />
      </div>
    </template>
  </GenericModalComponent>
</template>
