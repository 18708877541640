<script setup>
import invoke from 'lodash/invoke';
import { computed, onBeforeMount, ref } from 'vue';
import { FILE_TYPES, getValue } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { DragFileComponent } from '@/components';
import { useStoreModule } from '@/store/composable/useStoreModule';
import { cropImageToAspectRatio, downloadFile, isImageUnderMaxSize } from '@/utils';

import DamageImageTemplate from './DamageImageTemplate';
import PreviewDamageImageModal from './PreviewDamageImageModal';

const emits = defineEmits(['update:image']);

const props = defineProps({
  image: {
    type: [String, null],
    default: null,
  },
});

const croppedImage = ref(null);
const isPreviewModalOpen = ref(false);
const isValidImage = ref(undefined);
const isLoading = ref(false);
const dragFileComponent = ref(null);

const { vehicleLicensePlate } = useStoreModule(DOMAINS_MODEL.carsharing.vehicleDamages, {
  state: {
    vehicleLicensePlate: state => getValue(state, 'vehicle.data.licensePlate', ''),
  },
});
const isEditable = computed(() => !props.image);

onBeforeMount(() => {
  if (!isEditable.value) {
    croppedImage.value = props.image;
  }
});

const onChangeImage = async (files) => {
  const file = getValue(files, '0', null);

  if (file) {
    const fileReader = new FileReader();
    fileReader.onloadstart = () => {
      isLoading.value = true;
    };

    fileReader.onload = async () => {
      try {
        const crop = await cropImageToAspectRatio(fileReader.result, 9 / 16);
        isValidImage.value = isImageUnderMaxSize(crop);
        croppedImage.value = isValidImage.value ? crop : null;
        emits('update:image', croppedImage.value);
      } catch (_e) {
        isValidImage.value = false;
        invoke(dragFileComponent, 'value.removeFile', file, 0);
      }
      isLoading.value = false;
    };

    fileReader.readAsDataURL(file);
  }
};

const removeImage = () => {
  if (isEditable.value) {
    croppedImage.value = null;
    emits('update:image', null);
    isValidImage.value = undefined;
  }
};

const downloadImage = () => {
  downloadFile({ name: vehicleLicensePlate.value, url: croppedImage.value });
};

const onClickDamageTemplate = () => {
  isPreviewModalOpen.value = true;
};
</script>
<template>
  <div class="DamageImageUploader">
    <label class="d-block align-self-end emobg-font-weight-semibold mb-1">
      Damage image*
    </label>
    <span class="d-block emobg-color-ink-light mb-1">
      Ensure the damage is within the frame
    </span>
    <DragFileComponent
      v-if="!croppedImage"
      ref="dragFileComponent"
      :accepted-formats="`${FILE_TYPES.png}, ${FILE_TYPES.jpeg}, ${FILE_TYPES.jpg}`"
      :class="['DamageImageUploader__uploader', { 'disabled': isLoading }]"
      hidden
      @files="onChangeImage"
    >
      <div :class="['DamageImageUploader__box', { 'DamageImageUploader__box--error': isValidImage === false }]">
        <div class="d-flex flex-column h-100 text-center align-items-center justify-content-center p-4 emobg-color-ink-light">
          <template v-if="isValidImage === false">
            <ui-loader v-if="isLoading" />
            <ui-icon
              v-else
              :icon="ICONS.alertFull"
              :color="COLORS.danger"
              :size="ICONS_SIZES.xLarge"
            />
            <p class="mx-auto mt-3">
              Upload failed
            </p>
            <p class="emobg-font-xsmall mt-2 mb-1 emobg-font-line-height-large">
              Ensure image is in .jpg or .png, or try reducing the image size.
            </p>

            <ui-button class="mt-2">
              Select file
            </ui-button>
          </template>

          <template v-else>
            <ui-loader v-if="isLoading" />
            <ui-icon
              v-else
              :icon="ICONS.upload"
              :size="ICONS_SIZES.xLarge"
            />
            <p class="mx-auto mt-3 emobg-font-line-height-large">
              Drop your image here or <span class="emobg-color-primary emobg-font-weight-semibold">Browse</span>
            </p>
            <p class="emobg-font-xsmall mt-2 mb-1">
              Image format .jpg or .png
            </p>
          </template>
        </div>
      </div>
    </DragFileComponent>
    <div
      v-else
      style="max-width: fit-content;"
    >
      <DamageImageTemplate
        :src="croppedImage"
        :height="384"
        has-border-radius
        class="cursor-pointer"
        :on-click-image="onClickDamageTemplate"
      />
      <div class="d-flex justify-content-end pt-1">
        <ui-button
          :face="FACES.outline"
          :color="GRAYSCALE.inkLight"
          circle
          data-test-id="download-button"
          class="DamageImageUploader__action "
          @clickbutton="downloadImage"
        >
          <ui-icon
            :icon="ICONS.download"
            :size="ICONS_SIZES.xSmall"
          />
        </ui-button>

        <ui-button
          v-if="isEditable"
          :face="FACES.outline"
          :color="GRAYSCALE.inkLight"
          circle
          data-test-id="delete-button"
          class="DamageImageUploader__action ml-1"
          @clickbutton="removeImage"
        >
          <ui-icon
            :icon="ICONS.trash"
            :size="ICONS_SIZES.xSmall"
          />
        </ui-button>
      </div>
    </div>

    <PreviewDamageImageModal
      v-if="isPreviewModalOpen"
      :image="croppedImage"
      @closeModal="isPreviewModalOpen = false"
    />
  </div>
</template>
<style lang="scss">
@import "~@emobg/sass/colors/variables";

.DamageImageUploader {
  &__uploader {
    height: 384px;
    aspect-ratio: 9/16;
  }

  &__box {
    width: auto;
    height: 100%;
    background-color: map-get($motion-grayscale, "ground-lightest");
    border: 1px dashed map-get($motion-grayscale, "ground");
    border-radius: 4px;

    .emobg-font-xsmall {
      font-size: smaller;
    }

    &--error {
      border-color: map-get($motion-color-palette, "danger") !important;
    }
  }

  &__action {
    .Ui-Button {
      width: 28px !important;
      min-width: 28px !important;
      height: 28px !important;
      min-height: 28px !important;
    }
  }

  & + .MuiValidation__errorLabel {
    display: none !important;
  }
}
</style>
