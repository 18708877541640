<script>
import moment from 'moment-timezone';
import upperFirst from 'lodash/upperFirst';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import findIndex from 'lodash/findIndex';
import invoke from 'lodash/invoke';
import {
  base64WithoutName,
  camelCaseKeys,
  DATE_FORMAT,
  DATE_FORMAT_KEYS,
  FILE_TYPES,
  getImageName,
  getValue,
  navigationErrorHandler,
  removeBase64Header,
  sentenceCase,
} from '@emobg/web-utils';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { MuiDatePicker } from '@emobg/vue-base';
import {
  MuiAlgoliaSelect,
  MuiSelect,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import {
  CancelButton,
  ContentCellComponent,
  DragFileComponent,
  PermissionLink,
  TableComponent,
} from '@/components';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { DATE_INPUT_VALIDATIONS, downloadFile } from '@/utils';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import fleet from '../../../router/FleetRouterMap';
import crm from '../../../../CRM/router/CRMRouterMap';
import {
  DAMAGE_ATTACHMENT_SCHEMA,
  DAMAGES_STATUS,
  DAMAGES_STATUS_COLORS,
  DAMAGES_TYPES,
  EXTERIOR_DAMAGE_TYPES,
  isSystemUser,
} from '../damages.const';
import AttachmentPreviewModal from './VehicleDamageAttachments/AttachmentPreviewModal';
import DeleteAttachmentModal from './VehicleDamageAttachments/DeleteAttachmentModal';
import VehicleDamageArea from './VehicleDamageArea/VehicleDamageArea';
import DamageImageUploader from './DamageImageUploader';
import LegalCompliantGuardModal from './LegalCompliantGuardModal';

export default {
  name: 'VehicleDamageForm',
  directives: {
    Validate,
  },
  components: {
    LegalCompliantGuardModal,
    PermissionLink,
    MuiValidationWrapper,
    CancelButton,
    ContentCellComponent,
    MuiTextarea,
    MuiAlgoliaSelect,
    MuiDatePicker,
    MuiSelect,
    DragFileComponent,
    TableComponent,
    AttachmentPreviewModal,
    DeleteAttachmentModal,
    VehicleDamageArea,
    DamageImageUploader,
  },
  props: {
    vehicleUuid: {
      type: String,
      required: true,
    },
    damageUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      isFormValid: false,
      initialInputs: null,
      isEditingStatus: false,
      isDeleteAttachmentOpen: false,
      attachmentToDelete: null,
      isPreviewModalOpen: false,
      isLegalCompliantGuardModalOpen: false,
      startIndex: 0,
      attachments: [],
      attachmentsToDelete: [],
      currentAttachments: [],
      inputs: {
        status: DAMAGES_STATUS.pendingReview,
        damageImage: null,
        damage: {
          side: null,
          coordinateX: null,
          coordinateY: null,
        },
        vehicleArea: null,
        damageType: null,
        description: null,
        reportingUserUuid: null,
        reportingDate: null,
        responsibleUserUuid: null,
        bookingUuid: null,
        invoiceUuid: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userAccount: state => camelCaseKeys(state.user.data),
      activeOperatorUuid: state => state.operators.active.uuid,
      activeOperatorId: state => state.operators.active.id,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicleDamages, {
      vehicle: state => state.vehicle.data,
      damage: state => state.damage.data,
      newDamageStatus: state => state.newDamage.STATUS,
    }),
    existingReportingUserFilter() {
      return this.isOriginatedBySystem && this.inputs.reportingUserUuid ? `OR uuid: ${this.inputs.reportingUserUuid}` : '';
    },
    isOriginatedBySystem() {
      return this.isEditing ? isSystemUser(getValue(this, 'originatorUser.uuid', null)) : false;
    },
    isRejectedStatus() {
      return this.inputs.status === DAMAGES_STATUS.rejected;
    },
    isNotExteriorDamageType() {
      return this.inputs.damageType && !EXTERIOR_DAMAGE_TYPES.includes(this.inputs.damageType);
    },
    isEditing() {
      return !!this.damageUuid;
    },
    hasSameValues() {
      const currentData = omit(this.dataForRequest.data, ['attachments', 'attachmentsToDelete', 'vehicleUuid']);
      return this.isEditing && isEqual(currentData, this.initialInputs) && !this.attachments.length && !this.attachmentsToDelete.length;
    },
    vehicleName() {
      return `${this.vehicle.brand} ${this.vehicle.model} (${sentenceCase(this.vehicle.color)})`;
    },
    originatorUser() {
      return this.isEditing ? this.damage.originatorUser : this.userAccount;
    },
    attachmentsTransformed() {
      return map(this.attachments, attachment => ({
        imageUrl: `data:image/png;base64,${base64WithoutName(attachment)}`,
        imageName: getImageName(attachment),
        isNewAttachment: true,
      }));
    },
    isDamagePointFilled() {
      const { side, coordinateX, coordinateY } = this.inputs.damage;
      return !!(side && coordinateX && coordinateY);
    },
    attachmentsList() {
      return [...this.currentAttachments, ...this.attachmentsTransformed];
    },
    attachmentsSchema() {
      return DAMAGE_ATTACHMENT_SCHEMA(this.openPreviewModal);
    },
    attachmentsForPreview() {
      return map(this.attachmentsList, ({ imageUrl, imageName }) => ({ src: imageUrl, label: imageName }));
    },
    dataForRequest() {
      const data = cloneDeep(this.inputs);
      data.reportingDate = data.reportingDate ? moment(data.reportingDate).format(DATE_FORMAT.dateTime) : null;
      data.vehicleUuid = this.vehicleUuid;
      data.attachments = [];
      data.damageImage = data.damageImage ? removeBase64Header(data.damageImage) : data.damageImage;
      each(this.attachments, attachment => {
        data.attachments.push(base64WithoutName(attachment));
      });
      return this.isEditing ? { damageUuid: this.damageUuid, data: { ...data, attachmentsToDelete: this.attachmentsToDelete } } : { data };
    },
  },
  created() {
    this.fleet = fleet;
    this.crm = crm;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.DATE_FORMAT = DATE_FORMAT;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.DAMAGES_STATUS = DAMAGES_STATUS;
    this.DAMAGES_STATUS_COLORS = DAMAGES_STATUS_COLORS;
    this.DAMAGES_TYPES = DAMAGES_TYPES;
    this.FILE_TYPES = FILE_TYPES;
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.DATE_INPUT_VALIDATIONS = DATE_INPUT_VALIDATIONS;
    this.attachmentsRowActions = [
      {
        label: 'Preview attachment',
        labelClass: 'emobg-font-weight-semibold',
        action: this.openPreviewModal,
      },
      {
        label: 'Download attachment',
        labelClass: 'emobg-font-weight-semibold',
        action: attachment => downloadFile({ url: attachment.imageUrl, name: attachment.imageName || getImageName(attachment.imageUrl) }),
      },
      {
        label: 'Delete attachment',
        labelClass: 'emobg-color-danger emobg-font-weight-semibold',
        action: (attachment) => {
          this.attachmentToDelete = attachment;
          this.isDeleteAttachmentOpen = true;
        },
      },
    ];

    if (this.isEditing && this.damage) {
      this.inputs.status = this.damage.status;
      this.inputs.damage = this.damage.damage;
      this.inputs.vehicleArea = this.damage.vehicleArea;
      this.inputs.damageType = this.damage.damageType;
      this.inputs.description = this.damage.description;
      this.inputs.reportingUserUuid = this.damage.reportingUserUuid;
      this.inputs.reportingDate = this.damage.reportingDate ? moment(this.damage.reportingDate) : null;
      this.inputs.responsibleUserUuid = this.damage.responsibleUserUuid;
      this.inputs.bookingUuid = this.damage.bookingUuid;
      this.inputs.invoiceUuid = this.damage.invoiceUuid;
      this.currentAttachments = cloneDeep(this.damage.attachments);

      this.initialInputs = cloneDeep(this.inputs);
      this.initialInputs.reportingDate = this.damage.reportingDate;
    }
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.vehicleDamages, [
      'postVehicleDamage',
      'putVehicleDamage',
    ]),
    getValue,
    map,
    upperFirst,
    sentenceCase,
    base64WithoutName,
    isSystemUser,
    onStatusChange() {
      this.isEditingStatus = false;

      this.$nextTick(() => {
        const targetValidatorMethod = !this.inputs.description && !this.isRejectedStatus ? 'reset' : 'validate';
        invoke(this, `$refs.descriptionField.$el.MuiValidationManager.${targetValidatorMethod}`);
      });
    },
    onDeleteAttachment() {
      const targetArray = this.attachmentToDelete.isNewAttachment ? this.attachmentsTransformed : this.currentAttachments;
      const indexToDelete = findIndex(targetArray, ({ imageUrl }) => imageUrl === this.attachmentToDelete.imageUrl);
      const targetDeleteMethod = this.attachmentToDelete.isNewAttachment ? this.$refs.fileUploader.removeFile : this.$refs.fileUploader.removeExistingFile;

      targetDeleteMethod(this.attachmentToDelete, indexToDelete);

      if (!this.attachmentToDelete.isNewAttachment) {
        this.attachmentsToDelete.push(this.attachmentToDelete.imageUuid);
      }
    },
    openPreviewModal(_document, index) {
      this.startIndex = index;
      this.isPreviewModalOpen = true;
    },
    closeModal() {
      this.isPreviewModalOpen = false;
      this.isDeleteAttachmentOpen = false;
      this.isLegalCompliantGuardModalOpen = false;
      this.startIndex = 0;
      this.attachmentToDelete = null;
    },
    goToVehicleDamagesList() {
      this.$router.push({
        name: fleet.vehicles.detail.damages,
        params: {
          vehicle_uuid: this.vehicleUuid,
          refreshList: true,
        },
      }).catch(navigationErrorHandler);
    },
    saveDamageOrShowGuardModal() {
      const isCurrentStatusNotRepaired = getValue(this, 'damage.status', '') === DAMAGES_STATUS.notRepaired;

      if (this.inputs.status === DAMAGES_STATUS.notRepaired && !isCurrentStatusNotRepaired) {
        this.isLegalCompliantGuardModalOpen = true;
      } else {
        this.saveDamage();
      }
    },
    async saveDamage() {
      const request = this.isEditing ? this.putVehicleDamage : this.postVehicleDamage;
      const action = this.isEditing ? 'edited' : 'reported';
      await request(this.dataForRequest);

      if (this.newDamageStatus.ERROR) {
        this.$notify({
          message: 'We could not Report new damage. Try again.',
          type: NOTIFICATION_TYPES.error,
        });

        return;
      }

      this.$notify({
        message: `Damage successfully <span class="emobg-font-weight-semibold">${action}</span>`,
      });

      this.goToVehicleDamagesList();
    },
  },
};
</script>
<template>
  <div class="VehicleDamageForm d-flex flex-column flex-fill emobg-border-1 emobg-border-color-ground-light emobg-background-color-white">
    <h2 class="px-3 py-4 emobg-font-large emobg-background-color-ground-lightest">
      Vehicle details
    </h2>
    <div class="px-3 py-4">
      <PermissionLink
        :to="{
          name: fleet.vehicles.detail.details,
          params: {
            vehicle_uuid: getValue(vehicle, 'uuid', null),
          },
        }"
        :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingVehicles]"
        target="_blank"
        class="d-block pb-1 emobg-font-weight-semibold"
        data-test-id="vehicle_details-link"
      >
        {{ vehicle.licensePlate }}
      </PermissionLink>
      {{ vehicleName }}
    </div>
    <MuiValidationWrapper @areAllValid="isValid => isFormValid = isValid">
      <h2 class="px-3 py-4 emobg-font-large emobg-background-color-ground-lightest">
        Damage details
      </h2>
      <ui-alert
        :color="COLORS.warning"
        :icon="ICONS.alertAloneFull"
        class="d-flex px-3 pb-4 pt-3"
      >
        Ensure that no third-party information is visible on the damage picture, such as people or personal details.
      </ui-alert>
      <div class="row px-3">
        <div class="col-lg-3">
          <div class="mb-3">
            <ContentCellComponent
              v-if="!isEditingStatus"
              label="Damage status"
            >
              <div class="d-flex align-items-center">
                <ui-badge
                  :text="sentenceCase(inputs.status)"
                  :color="DAMAGES_STATUS_COLORS[inputs.status]"
                />
                <ui-icon
                  :icon="ICONS.edit"
                  :color="GRAYSCALE.inkLight"
                  :size="ICONS_SIZES.small"
                  class="ml-1 cursor-pointer"
                  data-test-id="edit_status-action"
                  @click="isEditingStatus = true"
                />
              </div>
            </ContentCellComponent>
            <div v-else>
              <MuiSelect
                v-model="inputs.status"
                v-validate="{
                  isRequired: true,
                }"
                :options="map(DAMAGES_STATUS, status => ({ label: sentenceCase(status), value: status }))"
                data-test-id="status-select"
                label="Damage status"
                placeholder="Select"
                name="status"
                class="w-100"
                @change="onStatusChange"
              >
                <template #selected="{ item }">
                  <ui-badge
                    :text="item.label"
                    :color="DAMAGES_STATUS_COLORS[item.value]"
                  />
                </template>
                <template #item="{ item }">
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <span class="emobg-font-weight-semibold">{{ item.label }}</span>
                    <ui-badge
                      :color="DAMAGES_STATUS_COLORS[item.value]"
                      circle
                    />
                  </div>
                </template>
              </MuiSelect>
            </div>
          </div>
          <div class="mb-3">
            <DamageImageUploader
              v-validate.update:image="{
                ...getValue(damage, 'imageUrl', null) ? {} : { isRequired: true }
              }"
              :image="getValue(damage, 'imageUrl', null)"
              @update:image="image => inputs.damageImage = image"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="mb-3">
            <VehicleDamageArea
              v-model="inputs.damage"
              :sides="vehicle.images"
            />
          </div>
        </div>
        <div class="col-lg-3">
          <div class="mb-3">
            <MuiAlgoliaSelect
              v-model="inputs.vehicleArea"
              v-validate="{
                isRequired: true,
              }"
              :title="({ internal_name }) => upperFirst(internal_name.replace(/\_/g, ' '))"
              :index="ALGOLIA_INDEXES.vehicleAreas"
              :filters="`vehicle_type: ${getValue(vehicle, 'type', '')}`"
              label="Vehicle area*"
              path-value="internal_name"
              class="w-100"
              placeholder="Select"
              name="area"
              data-test-id="area-select"
            />
          </div>
          <div class="mb-3">
            <MuiSelect
              v-model="inputs.damageType"
              v-validate="{
                isRequired: true,
              }"
              :options="map(DAMAGES_TYPES, type => ({ label: sentenceCase(type), value: type }))"
              label="Damage type*"
              class="w-100"
              placeholder="Select"
              name="type"
              data-test-id="type-select"
            />
            <span
              v-if="isNotExteriorDamageType"
              class="d-block emobg-font-x-small m-1"
            >
              Damage picture won't be shown to app user.
            </span>
          </div>
          <div class="mb-3">
            <label class="d-block emobg-font-weight-semibold mb-1">
              Description{{isRejectedStatus ? '*' : ''}}
            </label>
            <MuiTextarea
              ref="descriptionField"
              v-model="inputs.description"
              v-validate="{
                ...(isRejectedStatus) ? { isRequired: { message: 'Please provide the reason for rejecting the damage.' } } : {},
                isMinLength: {
                  message: 'We need at least 6 characters',
                  length: 6,
                }
              }"
              placeholder="Enter"
              data-test-id="description-textarea"
              name="description"
              rows="16"
            />
          </div>
        </div>
      </div>

      <div class="mb-4 px-3">
        <div class="emobg-border-color-ground-light emobg-border-top-1 my-4" />
        <div class="d-flex flex-fill justify-content-between mb-1 align-items-center">
          <label class="d-block align-self-end emobg-font-weight-semibold mb-1">
            Attachments
          </label>

          <DragFileComponent
            ref="fileUploader"
            v-model="attachments"
            :existing-files="currentAttachments"
            :accepted-formats="`${FILE_TYPES.image},${FILE_TYPES.pdf}`"
            hidden
            multiple
            @existingFileRemoved="files => currentAttachments = files"
          >
            <ui-button :face="FACES.outline">
              Upload attachment
            </ui-button>
          </DragFileComponent>
        </div>

        <TableComponent
          :schema="attachmentsSchema"
          :data="attachmentsList"
          :row-actions="attachmentsRowActions"
          empty-label="No attachments yet"
        />
      </div>

      <h2 class="px-3 py-4 emobg-font-large emobg-background-color-ground-lightest">
        Reporting source
      </h2>
      <div class="px-3 py-4">
        <div class="w-100 row">
          <div class="col-md-6 col-lg-4">
            <ContentCellComponent
              label="Agent"
              class="mb-3"
            >
              <RouterLink
                :to="{
                  name: crm.users.detail.index,
                  params: {
                    userUuid: getValue(originatorUser, 'uuid', null),
                  },
                }"
                target="_blank"
                class="cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none"
                data-test-id="agent-link"
              >
                {{ `${getValue(originatorUser, 'firstName', '')} ${getValue(originatorUser, 'lastName', '')}` }}
              </RouterLink>
            </ContentCellComponent>
          </div>

          <div class="col-md-6 col-lg-4 mb-3">
            <MuiAlgoliaSelect
              v-model="inputs.reportingUserUuid"
              v-validate="{
                isRequired: true,
              }"
              :title="({ first_name, last_name, email }) => `${first_name} ${last_name} - ${email}`"
              :index="ALGOLIA_INDEXES.users"
              :filters="`cs_operator_uuid: ${activeOperatorUuid} OR employee.company.cs_operator_uuid: ${activeOperatorUuid} ${existingReportingUserFilter}`"
              label="Reporting user*"
              path-value="uuid"
              :class="['w-100', { disabled: isOriginatedBySystem }]"
              placeholder="Select"
              name="reportingUser"
              data-test-id="reporting_user-select"
            />
          </div>

          <div class="col-md-6 col-lg-4 mb-3">
            <MuiDatePicker
              v-model="inputs.reportingDate"
              v-validate="{
                isRequired: true,
                isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              }"
              :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
              :disabled="isOriginatedBySystem"
              data-test-id="reporting_date-select"
              label="Reporting date*"
              name="reportingDate"
              class="w-100"
            />
          </div>
        </div>
      </div>

      <h2 class="px-3 py-4 emobg-font-large emobg-background-color-ground-lightest">
        Responsibility
      </h2>
      <div class="px-3 py-4">
        <div class="w-100 row">
          <div class="col-md-6 col-lg-4">
            <MuiAlgoliaSelect
              v-model="inputs.responsibleUserUuid"
              :title="({ first_name, last_name, email }) => `${first_name} ${last_name} - ${email}`"
              :filters="`cs_operator_uuid: ${activeOperatorUuid} OR employee.company.cs_operator_uuid: ${activeOperatorUuid}`"
              :index="ALGOLIA_INDEXES.users"
              label="Responsible user"
              path-value="uuid"
              class="w-100"
              placeholder="Select"
              name="responsible"
              data-test-id="responsible_user-select"
            />
          </div>

          <div class="col-md-6 col-lg-4">
            <MuiAlgoliaSelect
              v-model="inputs.bookingUuid"
              :disabled="!inputs.responsibleUserUuid"
              :title="({ id, start_ts }) => `#${id} - ${moment(start_ts, 'X').format(DATE_FORMAT.date)}`"
              :filters="`cs_operator_fk: ${activeOperatorId} AND user_uuid: ${inputs.responsibleUserUuid}`"
              :index="ALGOLIA_INDEXES.csBookings"
              label="Booking ID"
              path-value="uuid"
              class="w-100"
              placeholder="Select"
              name="booking"
              data-test-id="booking-select"
            />
          </div>

          <div class="col-md-6 col-lg-4">
            <MuiAlgoliaSelect
              v-model="inputs.invoiceUuid"
              :disabled="!inputs.responsibleUserUuid"
              :title="({ serie, number, date_ts }) => `${serie} ${number} - ${moment(date_ts, 'X').format(DATE_FORMAT.date)}`"
              :filters="`cs_operator_fk: ${activeOperatorId} AND user_uuid: ${inputs.responsibleUserUuid}`"
              :index="ALGOLIA_INDEXES.invoices"
              label="Invoice number"
              path-value="uuid"
              class="w-100"
              placeholder="Select"
              name="invoice"
              data-test-id="invoice-select"
            />
          </div>
        </div>
      </div>
    </MuiValidationWrapper>

    <div class="BottomActions d-flex p-3 emobg-border-1 emobg-border-color-ground-light position-sticky">
      <CancelButton
        class="mr-2"
        data-test-id="cancel-button"
        @click="goToVehicleDamagesList"
      />
      <ui-button
        :disabled="!isFormValid || !isDamagePointFilled || hasSameValues"
        :loading="newDamageStatus.LOADING"
        data-test-id="save-button"
        @clickbutton="saveDamageOrShowGuardModal"
      >
        {{ isEditing ? 'Save' : 'Report new damage' }}
      </ui-button>
    </div>
    <AttachmentPreviewModal
      v-if="isPreviewModalOpen"
      :attachments="attachmentsForPreview"
      :start-index="startIndex"
      @closeModal="closeModal"
    />

    <DeleteAttachmentModal
      v-if="isDeleteAttachmentOpen"
      :attachment="attachmentToDelete"
      :callback="onDeleteAttachment"
      @closeModal="closeModal"
    />
    <LegalCompliantGuardModal
      v-if="isLegalCompliantGuardModalOpen"
      :image="inputs.damageImage || getValue(damage, 'imageUrl', null)"
      :callback="saveDamage"
      @closeModal="closeModal"
    />
  </div>
</template>
<style lang="scss">
  .VehicleDamageForm {
    .BottomActions {
      bottom: 0;
      z-index: 1;
      align-items: center;
      justify-content: end;
      background-color: #fff;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.32);
    }

    .Ui-TextInput--disabled {
      background-color: initial;
    }
  }
</style>
