<script setup>
import { ref } from 'vue';
import { CancelButton, GenericModalComponent } from '@/components';
import DamageImageTemplate from './DamageImageTemplate';

const emits = defineEmits(['closeModal']);
const props = defineProps({
  image: {
    type: String,
    required: true,
  },
  callback: {
    type: Function,
    default: () => {},
  },
});
const isLegalCompliance = ref(false);

const onConfirm = () => {
  emits('closeModal');
  props.callback();
};
</script>

<template>
  <GenericModalComponent
    title="Is the picture legal compliant"
    :header="{ isClosable: true }"
    :size="SIZES.large"
    class="LegalCompliantGuardModal"
    data-test-id="legal-compliant-guard-modal"
    v-on="$listeners"
  >
    <template #body>
      <label class="d-block emobg-font-weight-semibold mb-1">
        Damage picture
      </label>
      <div class="d-flex flex-fill justify-content-center py-2 emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-ground-lighter mb-3">
        <DamageImageTemplate
          :src="image"
          :height="500"
        />
      </div>
      <ui-checkbox
        :checked="isLegalCompliance"
        caption="I confirm that there is no third-party information contained in the picture"
        name="legal_compliant-checkbox"
        data-test-id="legal_compliant-checkbox"
        class="d-block mb-3"
        @changevalue="({ detail }) => isLegalCompliance = detail"
      />
      <p>*If the damage picture is not legally compliant please change the status to Rejected.</p>
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <ui-button
        :disabled="!isLegalCompliance"
        narrow
        data-test-id="save-button"
        @click="onConfirm"
      >
        Save
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
