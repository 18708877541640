var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    {
      staticClass: "DamagesReportView d-flex flex-column flex-fill",
      class: {
        "justify-content-center": _vm.isLoading,
      },
    },
    [
      _vm.isLoading
        ? _c("ui-loader", { attrs: { label: "Loading..." } })
        : _c(
            "Transition",
            { attrs: { name: "page", mode: "out-in" } },
            [_c("RouterView")],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }