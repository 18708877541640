import { render, staticRenderFns } from "./VehicleDamageArea.vue?vue&type=template&id=4a089996&"
import script from "./VehicleDamageArea.vue?vue&type=script&lang=js&"
export * from "./VehicleDamageArea.vue?vue&type=script&lang=js&"
import style0 from "./VehicleDamageArea.vue?vue&type=style&index=0&id=4a089996&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a089996')) {
      api.createRecord('4a089996', component.options)
    } else {
      api.reload('4a089996', component.options)
    }
    module.hot.accept("./VehicleDamageArea.vue?vue&type=template&id=4a089996&", function () {
      api.rerender('4a089996', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/Carsharing/Vehicles/VehicleDamages/components/VehicleDamageArea/VehicleDamageArea.vue"
export default component.exports