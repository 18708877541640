var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "VehicleDamageArea" },
    [
      _c("ui-select", {
        staticClass: "d-block mb-3",
        staticStyle: { "max-width": "250px" },
        attrs: { value: _vm.side, label: "Vehicle side*" },
        domProps: { options: _vm.selectOptions },
        on: { selectoption: _vm.onChangeSide },
      }),
      _c("label", { staticClass: "d-block emobg-font-weight-semibold mb-1" }, [
        _vm._v(" Vehicle area highlight* "),
      ]),
      _c("span", { staticClass: "emobg-color-ink-light" }, [
        _vm._v(" Click on a vehicle area to highlight the reported damage "),
      ]),
      _c("div", { staticClass: "d-flex flex-wrap mt-3" }, [
        _c(
          "div",
          { staticClass: "col-12 emobg-background-color-ground-lightest" },
          [
            _vm.side
              ? _c(
                  "DamageCoordinates",
                  {
                    staticClass: "d-flex justify-content-center",
                    on: {
                      getCoordinates: (coordinates) =>
                        _vm.saveCoordinates(coordinates, _vm.side),
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "position-relative d-inline-block cursor-pointer",
                      },
                      [
                        _c("img", {
                          staticClass: "w-100",
                          staticStyle: { "max-height": "350px" },
                          attrs: {
                            src: _vm.selectedSideBlueprint,
                            ondragstart: "return false;",
                          },
                          on: {
                            load: function ($event) {
                              _vm.isSideLoaded = true
                            },
                          },
                        }),
                        _vm.isSideLoaded
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "VehicleDamageArea__label emobg-font-weight-semibold",
                              },
                              [_vm._v(" " + _vm._s(_vm.selectedSideName) + " ")]
                            )
                          : _vm._e(),
                        _vm.damagePoint.side === _vm.side &&
                        _vm.damagePoint.coordinateY &&
                        _vm.damagePoint.coordinateX
                          ? _c("div", {
                              staticClass:
                                "DamagesComponent__marker position-absolute emobg-background-color-danger emobg-border-radius-pill",
                              style: {
                                top: `${_vm.damagePoint.coordinateY}%`,
                                left: `${_vm.damagePoint.coordinateX}%`,
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center emobg-font-large emobg-font-weight-bold emobg-color-ink-light",
                    staticStyle: { height: "350px" },
                  },
                  [_vm._v(" Select a vehicle side ")]
                ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }