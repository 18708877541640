var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "DamageDetailView d-flex flex-column flex-fill",
      attrs: { "data-test-id": "damage_detail-view" },
    },
    [
      _c("h1", { staticClass: "mb-2" }, [
        _vm._v(
          " " +
            _vm._s(_vm.isEditing ? "Edit damage" : "Report new damage") +
            " "
        ),
      ]),
      _c(
        "VehicleDamageForm",
        _vm._b({}, "VehicleDamageForm", _vm.$props, false)
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }