<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  ContentCellComponent,
  DeleteButton,
  DeleteModalBodyComponent,
  DocumentPreview,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    ContentCellComponent,
    DocumentPreview,
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.vehicleDamages, {
      damage: state => state.damage.data,
    }),
  },
  methods: {
    get,
    async removeAttachment() {
      this.callback();
      this.$emit('closeModal');
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    class="DeleteAttachmentModal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        text="It cannot be undone"
        title="Delete this attachment?"
      />
      <ContentCellComponent
        :label="attachment.name || ''"
        class="mt-4"
      >
        <DocumentPreview
          v-if="attachment.imageUrl"
          :image="attachment.imageUrl"
          :name="attachment.imageName"
          is-download-hidden
          is-pointer-disabled
          data-test-id="thumbnail"
        />
      </ContentCellComponent>
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <DeleteButton
        data-test-id="delete-button"
        @click="removeAttachment"
      />
    </template>
  </GenericModalComponent>
</template>
