<script>
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import { FALLBACK_MESSAGE, getValue } from '@emobg/web-utils';
import DamageCoordinates from './DamageCoordinates';
import { DAMAGE_BLUEPRINT_SIDE_NAMES } from '../../damages.const';

export default {
  name: 'VehicleDamageArea',
  components: {
    DamageCoordinates,
  },
  model: {
    prop: 'damagePoint',
    event: 'change',
  },
  props: {
    damagePoint: {
      type: Object,
      default: null,
    },
    sides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      side: null,
      isSideLoaded: false,
    };
  },
  computed: {
    selectOptions() {
      const sortedSides = sortBy(this.sides, damage => getValue(DAMAGE_BLUEPRINT_SIDE_NAMES, `${damage.side}.position`, 0));
      return map(sortedSides, ({ side }) => ({ label: DAMAGE_BLUEPRINT_SIDE_NAMES[side].label, value: side }));
    },
    selectedSideBlueprint() {
      return this.side ? getValue(find(this.sides, ['side', this.side]), 'url', null) : null;
    },
    selectedSideName() {
      return this.side ? DAMAGE_BLUEPRINT_SIDE_NAMES[this.side].label : FALLBACK_MESSAGE.dash;
    },
  },
  created() {
    this.side = getValue(this.damagePoint, 'side', '');
  },
  methods: {
    onChangeSide({ detail }) {
      this.$emit('change', {
        side: null,
        coordinateX: null,
        coordinateY: null,
      });
      this.side = detail;
    },
    saveCoordinates(coordinates, side) {
      this.$emit('change', {
        side,
        coordinateX: (coordinates.x * 100) / coordinates.target.width,
        coordinateY: (coordinates.y * 100) / coordinates.target.height,
      });
    },
  },
};
</script>
<template>
  <div class="VehicleDamageArea">
    <ui-select
      :value="side"
      :options.prop="selectOptions"
      label="Vehicle side*"
      class="d-block mb-3"
      style="max-width: 250px;"
      @selectoption="onChangeSide"
    />
    <label class="d-block emobg-font-weight-semibold mb-1">
      Vehicle area highlight*
    </label>
    <span class="emobg-color-ink-light">
      Click on a vehicle area to highlight the reported damage
    </span>

    <div class="d-flex flex-wrap mt-3">
      <div class="col-12 emobg-background-color-ground-lightest">
        <DamageCoordinates
          v-if="side"
          class="d-flex justify-content-center"
          @getCoordinates="coordinates => saveCoordinates(coordinates, side)"
        >
          <div class="position-relative d-inline-block cursor-pointer">
            <img
              :src="selectedSideBlueprint"
              class="w-100"
              style="max-height: 350px;"
              ondragstart="return false;"
              @load="isSideLoaded = true"
            >
            <div
              v-if="isSideLoaded"
              class="VehicleDamageArea__label emobg-font-weight-semibold"
            >
              {{ selectedSideName }}
            </div>
            <div
              v-if="damagePoint.side === side && damagePoint.coordinateY && damagePoint.coordinateX"
              class="DamagesComponent__marker position-absolute emobg-background-color-danger emobg-border-radius-pill"
              :style="{
                top: `${damagePoint.coordinateY}%`,
                left: `${damagePoint.coordinateX}%`,
              }"
            />
          </div>
        </DamageCoordinates>
        <div
          v-else
          class="d-flex justify-content-center align-items-center emobg-font-large emobg-font-weight-bold emobg-color-ink-light"
          style="height: 350px;"
        >
          Select a vehicle side
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .VehicleDamageArea {
    &__label {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-bottom: 24px;
      transform: translate(-50%);
    }
  }
</style>
