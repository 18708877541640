var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "DamageImageUploader" },
    [
      _c(
        "label",
        {
          staticClass: "d-block align-self-end emobg-font-weight-semibold mb-1",
        },
        [_vm._v(" Damage image* ")]
      ),
      _c("span", { staticClass: "d-block emobg-color-ink-light mb-1" }, [
        _vm._v(" Ensure the damage is within the frame "),
      ]),
      !_setup.croppedImage
        ? _c(
            _setup.DragFileComponent,
            {
              ref: "dragFileComponent",
              class: [
                "DamageImageUploader__uploader",
                { disabled: _setup.isLoading },
              ],
              attrs: {
                "accepted-formats": `${_setup.FILE_TYPES.png}, ${_setup.FILE_TYPES.jpeg}, ${_setup.FILE_TYPES.jpg}`,
                hidden: "",
              },
              on: { files: _setup.onChangeImage },
            },
            [
              _c(
                "div",
                {
                  class: [
                    "DamageImageUploader__box",
                    {
                      "DamageImageUploader__box--error":
                        _setup.isValidImage === false,
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column h-100 text-center align-items-center justify-content-center p-4 emobg-color-ink-light",
                    },
                    [
                      _setup.isValidImage === false
                        ? [
                            _setup.isLoading
                              ? _c("ui-loader")
                              : _c("ui-icon", {
                                  attrs: {
                                    icon: _vm.ICONS.alertFull,
                                    color: _vm.COLORS.danger,
                                    size: _vm.ICONS_SIZES.xLarge,
                                  },
                                }),
                            _c("p", { staticClass: "mx-auto mt-3" }, [
                              _vm._v(" Upload failed "),
                            ]),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "emobg-font-xsmall mt-2 mb-1 emobg-font-line-height-large",
                              },
                              [
                                _vm._v(
                                  " Ensure image is in .jpg or .png, or try reducing the image size. "
                                ),
                              ]
                            ),
                            _c("ui-button", { staticClass: "mt-2" }, [
                              _vm._v(" Select file "),
                            ]),
                          ]
                        : [
                            _setup.isLoading
                              ? _c("ui-loader")
                              : _c("ui-icon", {
                                  attrs: {
                                    icon: _vm.ICONS.upload,
                                    size: _vm.ICONS_SIZES.xLarge,
                                  },
                                }),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mx-auto mt-3 emobg-font-line-height-large",
                              },
                              [
                                _vm._v(" Drop your image here or "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "emobg-color-primary emobg-font-weight-semibold",
                                  },
                                  [_vm._v("Browse")]
                                ),
                              ]
                            ),
                            _c(
                              "p",
                              { staticClass: "emobg-font-xsmall mt-2 mb-1" },
                              [_vm._v(" Image format .jpg or .png ")]
                            ),
                          ],
                    ],
                    2
                  ),
                ]
              ),
            ]
          )
        : _c(
            "div",
            { staticStyle: { "max-width": "fit-content" } },
            [
              _c(_setup.DamageImageTemplate, {
                staticClass: "cursor-pointer",
                attrs: {
                  src: _setup.croppedImage,
                  height: 384,
                  "has-border-radius": "",
                  "on-click-image": _setup.onClickDamageTemplate,
                },
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end pt-1" },
                [
                  _c(
                    "ui-button",
                    {
                      staticClass: "DamageImageUploader__action",
                      attrs: {
                        face: _vm.FACES.outline,
                        color: _vm.GRAYSCALE.inkLight,
                        circle: "",
                        "data-test-id": "download-button",
                      },
                      on: { clickbutton: _setup.downloadImage },
                    },
                    [
                      _c("ui-icon", {
                        attrs: {
                          icon: _vm.ICONS.download,
                          size: _vm.ICONS_SIZES.xSmall,
                        },
                      }),
                    ],
                    1
                  ),
                  _setup.isEditable
                    ? _c(
                        "ui-button",
                        {
                          staticClass: "DamageImageUploader__action ml-1",
                          attrs: {
                            face: _vm.FACES.outline,
                            color: _vm.GRAYSCALE.inkLight,
                            circle: "",
                            "data-test-id": "delete-button",
                          },
                          on: { clickbutton: _setup.removeImage },
                        },
                        [
                          _c("ui-icon", {
                            attrs: {
                              icon: _vm.ICONS.trash,
                              size: _vm.ICONS_SIZES.xSmall,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
      _setup.isPreviewModalOpen
        ? _c(_setup.PreviewDamageImageModal, {
            attrs: { image: _setup.croppedImage },
            on: {
              closeModal: function ($event) {
                _setup.isPreviewModalOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }