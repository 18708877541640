var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "VehicleDamageForm d-flex flex-column flex-fill emobg-border-1 emobg-border-color-ground-light emobg-background-color-white",
    },
    [
      _c(
        "h2",
        {
          staticClass:
            "px-3 py-4 emobg-font-large emobg-background-color-ground-lightest",
        },
        [_vm._v(" Vehicle details ")]
      ),
      _c(
        "div",
        { staticClass: "px-3 py-4" },
        [
          _c(
            "PermissionLink",
            {
              staticClass: "d-block pb-1 emobg-font-weight-semibold",
              attrs: {
                to: {
                  name: _vm.fleet.vehicles.detail.details,
                  params: {
                    vehicle_uuid: _vm.getValue(_vm.vehicle, "uuid", null),
                  },
                },
                "link-permissions": [
                  _vm.CARSHARING_PERMISSIONS.viewCarsharingVehicles,
                ],
                target: "_blank",
                "data-test-id": "vehicle_details-link",
              },
            },
            [_vm._v(" " + _vm._s(_vm.vehicle.licensePlate) + " ")]
          ),
          _vm._v(" " + _vm._s(_vm.vehicleName) + " "),
        ],
        1
      ),
      _c(
        "MuiValidationWrapper",
        { on: { areAllValid: (isValid) => (_vm.isFormValid = isValid) } },
        [
          _c(
            "h2",
            {
              staticClass:
                "px-3 py-4 emobg-font-large emobg-background-color-ground-lightest",
            },
            [_vm._v(" Damage details ")]
          ),
          _c(
            "ui-alert",
            {
              staticClass: "d-flex px-3 pb-4 pt-3",
              attrs: {
                color: _vm.COLORS.warning,
                icon: _vm.ICONS.alertAloneFull,
              },
            },
            [
              _vm._v(
                " Ensure that no third-party information is visible on the damage picture, such as people or personal details. "
              ),
            ]
          ),
          _c("div", { staticClass: "row px-3" }, [
            _c("div", { staticClass: "col-lg-3" }, [
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  !_vm.isEditingStatus
                    ? _c(
                        "ContentCellComponent",
                        { attrs: { label: "Damage status" } },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("ui-badge", {
                                attrs: {
                                  text: _vm.sentenceCase(_vm.inputs.status),
                                  color:
                                    _vm.DAMAGES_STATUS_COLORS[
                                      _vm.inputs.status
                                    ],
                                },
                              }),
                              _c("ui-icon", {
                                staticClass: "ml-1 cursor-pointer",
                                attrs: {
                                  icon: _vm.ICONS.edit,
                                  color: _vm.GRAYSCALE.inkLight,
                                  size: _vm.ICONS_SIZES.small,
                                  "data-test-id": "edit_status-action",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.isEditingStatus = true
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        [
                          _c("MuiSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n                isRequired: true,\n              }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              options: _vm.map(
                                _vm.DAMAGES_STATUS,
                                (status) => ({
                                  label: _vm.sentenceCase(status),
                                  value: status,
                                })
                              ),
                              "data-test-id": "status-select",
                              label: "Damage status",
                              placeholder: "Select",
                              name: "status",
                            },
                            on: { change: _vm.onStatusChange },
                            scopedSlots: _vm._u([
                              {
                                key: "selected",
                                fn: function ({ item }) {
                                  return [
                                    _c("ui-badge", {
                                      attrs: {
                                        text: item.label,
                                        color:
                                          _vm.DAMAGES_STATUS_COLORS[item.value],
                                      },
                                    }),
                                  ]
                                },
                              },
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-100 d-flex align-items-center justify-content-between",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "emobg-font-weight-semibold",
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        ),
                                        _c("ui-badge", {
                                          attrs: {
                                            color:
                                              _vm.DAMAGES_STATUS_COLORS[
                                                item.value
                                              ],
                                            circle: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.inputs.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "status", $$v)
                              },
                              expression: "inputs.status",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("DamageImageUploader", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.update:image",
                        value: {
                          ...(_vm.getValue(_vm.damage, "imageUrl", null)
                            ? {}
                            : { isRequired: true }),
                        },
                        expression:
                          "{\n              ...getValue(damage, 'imageUrl', null) ? {} : { isRequired: true }\n            }",
                        modifiers: { "update:image": true },
                      },
                    ],
                    attrs: {
                      image: _vm.getValue(_vm.damage, "imageUrl", null),
                    },
                    on: {
                      "update:image": (image) =>
                        (_vm.inputs.damageImage = image),
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-lg-6" }, [
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("VehicleDamageArea", {
                    attrs: { sides: _vm.vehicle.images },
                    model: {
                      value: _vm.inputs.damage,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "damage", $$v)
                      },
                      expression: "inputs.damage",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-lg-3" }, [
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("MuiAlgoliaSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                        },
                        expression:
                          "{\n              isRequired: true,\n            }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      title: ({ internal_name }) =>
                        _vm.upperFirst(internal_name.replace(/\_/g, " ")),
                      index: _vm.ALGOLIA_INDEXES.vehicleAreas,
                      filters: `vehicle_type: ${_vm.getValue(
                        _vm.vehicle,
                        "type",
                        ""
                      )}`,
                      label: "Vehicle area*",
                      "path-value": "internal_name",
                      placeholder: "Select",
                      name: "area",
                      "data-test-id": "area-select",
                    },
                    model: {
                      value: _vm.inputs.vehicleArea,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "vehicleArea", $$v)
                      },
                      expression: "inputs.vehicleArea",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("MuiSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                        },
                        expression:
                          "{\n              isRequired: true,\n            }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      options: _vm.map(_vm.DAMAGES_TYPES, (type) => ({
                        label: _vm.sentenceCase(type),
                        value: type,
                      })),
                      label: "Damage type*",
                      placeholder: "Select",
                      name: "type",
                      "data-test-id": "type-select",
                    },
                    model: {
                      value: _vm.inputs.damageType,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "damageType", $$v)
                      },
                      expression: "inputs.damageType",
                    },
                  }),
                  _vm.isNotExteriorDamageType
                    ? _c(
                        "span",
                        { staticClass: "d-block emobg-font-x-small m-1" },
                        [_vm._v(" Damage picture won't be shown to app user. ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c(
                    "label",
                    { staticClass: "d-block emobg-font-weight-semibold mb-1" },
                    [
                      _vm._v(
                        " Description" +
                          _vm._s(_vm.isRejectedStatus ? "*" : "") +
                          " "
                      ),
                    ]
                  ),
                  _c("MuiTextarea", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          ...(_vm.isRejectedStatus
                            ? {
                                isRequired: {
                                  message:
                                    "Please provide the reason for rejecting the damage.",
                                },
                              }
                            : {}),
                          isMinLength: {
                            message: "We need at least 6 characters",
                            length: 6,
                          },
                        },
                        expression:
                          "{\n              ...(isRejectedStatus) ? { isRequired: { message: 'Please provide the reason for rejecting the damage.' } } : {},\n              isMinLength: {\n                message: 'We need at least 6 characters',\n                length: 6,\n              }\n            }",
                      },
                    ],
                    ref: "descriptionField",
                    attrs: {
                      placeholder: "Enter",
                      "data-test-id": "description-textarea",
                      name: "description",
                      rows: "16",
                    },
                    model: {
                      value: _vm.inputs.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "description", $$v)
                      },
                      expression: "inputs.description",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "mb-4 px-3" },
            [
              _c("div", {
                staticClass:
                  "emobg-border-color-ground-light emobg-border-top-1 my-4",
              }),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-fill justify-content-between mb-1 align-items-center",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "d-block align-self-end emobg-font-weight-semibold mb-1",
                    },
                    [_vm._v(" Attachments ")]
                  ),
                  _c(
                    "DragFileComponent",
                    {
                      ref: "fileUploader",
                      attrs: {
                        "existing-files": _vm.currentAttachments,
                        "accepted-formats": `${_vm.FILE_TYPES.image},${_vm.FILE_TYPES.pdf}`,
                        hidden: "",
                        multiple: "",
                      },
                      on: {
                        existingFileRemoved: (files) =>
                          (_vm.currentAttachments = files),
                      },
                      model: {
                        value: _vm.attachments,
                        callback: function ($$v) {
                          _vm.attachments = $$v
                        },
                        expression: "attachments",
                      },
                    },
                    [
                      _c("ui-button", { attrs: { face: _vm.FACES.outline } }, [
                        _vm._v(" Upload attachment "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("TableComponent", {
                attrs: {
                  schema: _vm.attachmentsSchema,
                  data: _vm.attachmentsList,
                  "row-actions": _vm.attachmentsRowActions,
                  "empty-label": "No attachments yet",
                },
              }),
            ],
            1
          ),
          _c(
            "h2",
            {
              staticClass:
                "px-3 py-4 emobg-font-large emobg-background-color-ground-lightest",
            },
            [_vm._v(" Reporting source ")]
          ),
          _c("div", { staticClass: "px-3 py-4" }, [
            _c("div", { staticClass: "w-100 row" }, [
              _c(
                "div",
                { staticClass: "col-md-6 col-lg-4" },
                [
                  _c(
                    "ContentCellComponent",
                    { staticClass: "mb-3", attrs: { label: "Agent" } },
                    [
                      _c(
                        "RouterLink",
                        {
                          staticClass:
                            "cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none",
                          attrs: {
                            to: {
                              name: _vm.crm.users.detail.index,
                              params: {
                                userUuid: _vm.getValue(
                                  _vm.originatorUser,
                                  "uuid",
                                  null
                                ),
                              },
                            },
                            target: "_blank",
                            "data-test-id": "agent-link",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                `${_vm.getValue(
                                  _vm.originatorUser,
                                  "firstName",
                                  ""
                                )} ${_vm.getValue(
                                  _vm.originatorUser,
                                  "lastName",
                                  ""
                                )}`
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-6 col-lg-4 mb-3" },
                [
                  _c("MuiAlgoliaSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                        },
                        expression:
                          "{\n              isRequired: true,\n            }",
                      },
                    ],
                    class: ["w-100", { disabled: _vm.isOriginatedBySystem }],
                    attrs: {
                      title: ({ first_name, last_name, email }) =>
                        `${first_name} ${last_name} - ${email}`,
                      index: _vm.ALGOLIA_INDEXES.users,
                      filters: `cs_operator_uuid: ${_vm.activeOperatorUuid} OR employee.company.cs_operator_uuid: ${_vm.activeOperatorUuid} ${_vm.existingReportingUserFilter}`,
                      label: "Reporting user*",
                      "path-value": "uuid",
                      placeholder: "Select",
                      name: "reportingUser",
                      "data-test-id": "reporting_user-select",
                    },
                    model: {
                      value: _vm.inputs.reportingUserUuid,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "reportingUserUuid", $$v)
                      },
                      expression: "inputs.reportingUserUuid",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-6 col-lg-4 mb-3" },
                [
                  _c("MuiDatePicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                          isValidDate: _vm.DATE_INPUT_VALIDATIONS.validDate,
                        },
                        expression:
                          "{\n              isRequired: true,\n              isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n            }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      "date-format-key": _vm.DATE_FORMAT_KEYS.defaultExtended,
                      disabled: _vm.isOriginatedBySystem,
                      "data-test-id": "reporting_date-select",
                      label: "Reporting date*",
                      name: "reportingDate",
                    },
                    model: {
                      value: _vm.inputs.reportingDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "reportingDate", $$v)
                      },
                      expression: "inputs.reportingDate",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "h2",
            {
              staticClass:
                "px-3 py-4 emobg-font-large emobg-background-color-ground-lightest",
            },
            [_vm._v(" Responsibility ")]
          ),
          _c("div", { staticClass: "px-3 py-4" }, [
            _c("div", { staticClass: "w-100 row" }, [
              _c(
                "div",
                { staticClass: "col-md-6 col-lg-4" },
                [
                  _c("MuiAlgoliaSelect", {
                    staticClass: "w-100",
                    attrs: {
                      title: ({ first_name, last_name, email }) =>
                        `${first_name} ${last_name} - ${email}`,
                      filters: `cs_operator_uuid: ${_vm.activeOperatorUuid} OR employee.company.cs_operator_uuid: ${_vm.activeOperatorUuid}`,
                      index: _vm.ALGOLIA_INDEXES.users,
                      label: "Responsible user",
                      "path-value": "uuid",
                      placeholder: "Select",
                      name: "responsible",
                      "data-test-id": "responsible_user-select",
                    },
                    model: {
                      value: _vm.inputs.responsibleUserUuid,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "responsibleUserUuid", $$v)
                      },
                      expression: "inputs.responsibleUserUuid",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-6 col-lg-4" },
                [
                  _c("MuiAlgoliaSelect", {
                    staticClass: "w-100",
                    attrs: {
                      disabled: !_vm.inputs.responsibleUserUuid,
                      title: ({ id, start_ts }) =>
                        `#${id} - ${_vm
                          .moment(start_ts, "X")
                          .format(_vm.DATE_FORMAT.date)}`,
                      filters: `cs_operator_fk: ${_vm.activeOperatorId} AND user_uuid: ${_vm.inputs.responsibleUserUuid}`,
                      index: _vm.ALGOLIA_INDEXES.csBookings,
                      label: "Booking ID",
                      "path-value": "uuid",
                      placeholder: "Select",
                      name: "booking",
                      "data-test-id": "booking-select",
                    },
                    model: {
                      value: _vm.inputs.bookingUuid,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "bookingUuid", $$v)
                      },
                      expression: "inputs.bookingUuid",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-6 col-lg-4" },
                [
                  _c("MuiAlgoliaSelect", {
                    staticClass: "w-100",
                    attrs: {
                      disabled: !_vm.inputs.responsibleUserUuid,
                      title: ({ serie, number, date_ts }) =>
                        `${serie} ${number} - ${_vm
                          .moment(date_ts, "X")
                          .format(_vm.DATE_FORMAT.date)}`,
                      filters: `cs_operator_fk: ${_vm.activeOperatorId} AND user_uuid: ${_vm.inputs.responsibleUserUuid}`,
                      index: _vm.ALGOLIA_INDEXES.invoices,
                      label: "Invoice number",
                      "path-value": "uuid",
                      placeholder: "Select",
                      name: "invoice",
                      "data-test-id": "invoice-select",
                    },
                    model: {
                      value: _vm.inputs.invoiceUuid,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "invoiceUuid", $$v)
                      },
                      expression: "inputs.invoiceUuid",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "BottomActions d-flex p-3 emobg-border-1 emobg-border-color-ground-light position-sticky",
        },
        [
          _c("CancelButton", {
            staticClass: "mr-2",
            attrs: { "data-test-id": "cancel-button" },
            on: { click: _vm.goToVehicleDamagesList },
          }),
          _c(
            "ui-button",
            {
              attrs: {
                disabled:
                  !_vm.isFormValid ||
                  !_vm.isDamagePointFilled ||
                  _vm.hasSameValues,
                loading: _vm.newDamageStatus.LOADING,
                "data-test-id": "save-button",
              },
              on: { clickbutton: _vm.saveDamageOrShowGuardModal },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.isEditing ? "Save" : "Report new damage") + " "
              ),
            ]
          ),
        ],
        1
      ),
      _vm.isPreviewModalOpen
        ? _c("AttachmentPreviewModal", {
            attrs: {
              attachments: _vm.attachmentsForPreview,
              "start-index": _vm.startIndex,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteAttachmentOpen
        ? _c("DeleteAttachmentModal", {
            attrs: {
              attachment: _vm.attachmentToDelete,
              callback: _vm.onDeleteAttachment,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isLegalCompliantGuardModalOpen
        ? _c("LegalCompliantGuardModal", {
            attrs: {
              image:
                _vm.inputs.damageImage ||
                _vm.getValue(_vm.damage, "imageUrl", null),
              callback: _vm.saveDamage,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }