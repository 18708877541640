var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeleteAttachmentModal",
        attrs: { header: null },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("DeleteModalBodyComponent", {
                  attrs: {
                    text: "It cannot be undone",
                    title: "Delete this attachment?",
                  },
                }),
                _c(
                  "ContentCellComponent",
                  {
                    staticClass: "mt-4",
                    attrs: { label: _vm.attachment.name || "" },
                  },
                  [
                    _vm.attachment.imageUrl
                      ? _c("DocumentPreview", {
                          attrs: {
                            image: _vm.attachment.imageUrl,
                            name: _vm.attachment.imageName,
                            "is-download-hidden": "",
                            "is-pointer-disabled": "",
                            "data-test-id": "thumbnail",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c("DeleteButton", {
                  attrs: { "data-test-id": "delete-button" },
                  on: { click: _vm.removeAttachment },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }