var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.GenericModalComponent,
    _vm._g(
      {
        staticClass: "LegalCompliantGuardModal",
        attrs: {
          title: "Is the picture legal compliant",
          header: { isClosable: true },
          size: _vm.SIZES.large,
          "data-test-id": "legal-compliant-guard-modal",
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "label",
                  { staticClass: "d-block emobg-font-weight-semibold mb-1" },
                  [_vm._v(" Damage picture ")]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-fill justify-content-center py-2 emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-ground-lighter mb-3",
                  },
                  [
                    _c(_setup.DamageImageTemplate, {
                      attrs: { src: _vm.image, height: 500 },
                    }),
                  ],
                  1
                ),
                _c("ui-checkbox", {
                  staticClass: "d-block mb-3",
                  attrs: {
                    checked: _setup.isLegalCompliance,
                    caption:
                      "I confirm that there is no third-party information contained in the picture",
                    name: "legal_compliant-checkbox",
                    "data-test-id": "legal_compliant-checkbox",
                  },
                  on: {
                    changevalue: ({ detail }) =>
                      (_setup.isLegalCompliance = detail),
                  },
                }),
                _c("p", [
                  _vm._v(
                    "*If the damage picture is not legally compliant please change the status to Rejected."
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(_setup.CancelButton, {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c(
                  "ui-button",
                  {
                    attrs: {
                      disabled: !_setup.isLegalCompliance,
                      narrow: "",
                      "data-test-id": "save-button",
                    },
                    on: { click: _setup.onConfirm },
                  },
                  [_vm._v(" Save ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }